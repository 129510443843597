<template>
  <div class="relative" id="home">
    <div aria-hidden="true" class="background-blur">
      <div class="blur bg-gradient-1"></div>
      <div class="blur bg-gradient-2"></div>
    </div>
    <nav class="navbar">
      <div>
        <v-img
          @click="goTo('/')"
          aspect-ratio="1"
          class="img-logo"
          src="../../assets/logo.png"
          style="cursor: pointer"
          contain
        ></v-img>
      </div>
      <div>
        <v-btn
          v-if="currentPath !== '/login'"
          :to="{ path: '/', hash: '#contacto' }"
          text
          class="ml-2 d-none d-md-inline-flex"
        >
          <span style="text-transform: capitalize"> Contacto </span>
        </v-btn>
        <v-btn
          v-if="currentPath !== '/login'"
          :to="{ path: '/', hash: '#planes' }"
          text
          class="ml-2 d-none d-md-inline-flex"
        >
          <span style="text-transform: capitalize"> Planes </span>
        </v-btn>
        <v-btn
          v-if="currentPath !== '/login'"
          to="/registro"
          color="primary"
          depressed
          class="ml-3 d-none d-md-inline-flex"
        >
          <span style="text-transform: capitalize"
            >Comienza <span style="text-transform: lowercase">gratis</span>
          </span>
        </v-btn>
        <v-btn
          v-if="currentPath !== '/login'"
          to="/login"
          color="light_primary"
          outlined
          class="ml-3 d-none d-md-inline-flex"
        >
          <span style="text-transform: capitalize"
            >Iniciar <span style="text-transform: lowercase">sesión</span>
          </span>
        </v-btn>
        <v-btn @click="changeTheme" icon class="ml-3">
          <v-icon color="accent">{{
            $vuetify.theme.dark
              ? "mdi-weather-night"
              : "mdi-white-balance-sunny"
          }}</v-icon></v-btn
        >
        <v-btn
          v-if="currentPath !== '/login'"
          @click="toggleMobileMenu = !toggleMobileMenu"
          icon
          class="ml-5 mr-3 d-inline-flex d-md-none"
        >
          <v-icon color="accent">{{
            toggleMobileMenu ? "mdi-close" : "mdi-menu"
          }}</v-icon></v-btn
        >
      </div>
    </nav>
    <v-divider v-if="toggleMobileMenu"></v-divider>
    <div v-if="toggleMobileMenu && currentPath !== '/login'" class="px-8">
      <v-btn class="mt-3" block :to="{ path: '/', hash: '#contacto' }" text>
        <span style="text-transform: capitalize"> Contacto </span>
      </v-btn>
      <v-btn class="mt-3" block :to="{ path: '/', hash: '#planes' }" text>
        <span style="text-transform: capitalize"> Planes </span>
      </v-btn>
      <v-btn class="mt-3" block to="/registro" color="primary" depressed>
        <span style="text-transform: capitalize"
          >Comienza <span style="text-transform: lowercase">gratis</span>
        </span>
      </v-btn>
      <v-btn class="my-3" block to="/login" color="light_primary" outlined>
        <span style="text-transform: capitalize"
          >Iniciar <span style="text-transform: lowercase">sesión</span>
        </span>
      </v-btn>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "MainLayout",
  data() {
    return {
      currentYear: moment().format("YYYY"),
      toggleMobileMenu: false,
    };
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("xumed-dark-theme", this.$vuetify.theme.dark);
    },
    goTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.navbar {
  background: rgba(255, 255, 255, 0) !important;
  margin: 0;
  padding: 0px 75px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    padding: 5px 15px;
  }
}

.img-logo {
  height: 75px;
  width: 75px;
  @media (max-width: 768px) {
    height: 65px;
    width: 65px;
  }
}

.relative {
  position: relative;
}

.background-blur {
  position: absolute;
  inset: 0;
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 -52px;
  opacity: 0.5;
}

.background-blur .blur {
  filter: blur(106px);
}

.bg-gradient-1 {
  height: 27rem;
  background: linear-gradient(to bottom right, #1d7def, #2eb6b2);
}

.bg-gradient-2 {
  height: 24rem;
  background: linear-gradient(to right, #4cbfc8, #7bb6ff);
}

.content {
  position: relative;
  margin-left: auto;
}
</style>
